<template>
    <div class="modal-basic">
        <div class="head">
            <div class="title" :class="options.titleClass" v-html="`서류가 반려되었어요!`" />
            <!-- <img @click="$emit('close')" :src="require(`@/assets/images/icons/close.png`)" width="24px" height="24px" /> -->
        </div>
        <div class="body">
            <p class="m-b-8">아래와 같은 사유로 반려되었습니다. <br> 궁금한 점은 고객센터에 문의해주세요 :)</p>
            <div class="reasons">
                <div class="reason" v-for="reason in reasons" :key="reason.id">
                    <div class="spoqa-f-bold f-12" v-html="reason.name" />
                    <div class="desc f-14" v-html="reason.rejection_reason" />
                </div>
            </div>
        </div>
        <BottomButton
            @click="goCertCenter"
            v-if="!options.hideBottomBtn"
            :non-fixed="true"
            :black-btn="true"
            label="인증센터로 가기"
        />
    </div>
</template>

<script>
export default {
    name: 'ModalBadgeDeclined',
    props: ['options'],
    computed: {
        reasons() {
            return this.options.reasons
        },
    },
    methods: {
        goCertCenter() {
            this.$emit('close', 1)
        },
    },
}
</script>

<style scoped lang="scss">
.head {
    @include between;
}
.body {
    line-height: 24px;
}
.reasons {
    background: $bg-danger;
    padding: 8px 16px;

    .reason {
        margin-bottom: 12px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}
</style>
